import React from 'react';

export const Footer = () => {
    return (
        <footer>
            <div className="container-fluid row mx-auto">
                <div className="text-center text-light col-xl-4 my-auto py-3">
                    <div className="mx-auto contact-info-static">
                        <div className="text-left contact-item">Email: <a className="text-light" href="mailto:contact@alphalowvoltagesystems.com">contact@alphalowvoltagesystems.com</a></div>
                        <div className="text-left contact-item">Phone: <a className="text-light" href="tel:573-550-8287">573-550-8287</a></div>
                    </div>
                </div>
                <div className="text-center text-light col-xl-4 my-auto p-5">
                    <span>&copy; {new Date().getFullYear()} <a className="text-light" href="/">Alpha Low Voltage Systems</a></span>
                </div>
            </div>
        </footer>
    )
}
