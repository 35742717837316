import React from 'react';

export const Content = () => {
    return (
        <div className="about-body text-center w-75 mx-auto">
            <h2 className="heading my-5 font-weight-bold">Related Content</h2>

            <div className="card link-div my-3 py-2">
                <h4>Fire Alarm</h4>
                <a href="https://www.jensenhughes.com/insights/fire-alarm-system-basics-the-four-things-you-need-to-know" target="_blank" rel="noopener noreferrer">Fire Alarm Basics</a>
                <a href="https://safetyequipmentreview.in/fire-alarm-system-design-and-installation-consideration/" target="_blank" rel="noopener noreferrer">Fire Alarm Design Considerations</a>
                <a href="https://commerce.boschsecurity.com/us/en/Product-Segments/c/1" target="_blank" rel="noopener noreferrer">Bosch Product Catalog</a>
                <a href="https://www.boschsecurity.com/us/en/solutions/fire-alarm-systems/" target="_blank" rel="noopener noreferrer">Bosch Fire Alarm Systems</a>
            </div>
            <div className="card link-div my-3 py-2">
                <h4>Access Control</h4>
                <a href="https://kintronics.com/door-access-control-works/" target="_blank" rel="noopener noreferrer">Door Access Control Basics</a>
                <a href="https://www.boschsecurity.com/xc/en/solutions/access-control-systems/" target="_blank" rel="noopener noreferrer">Bosch Access Control Systems</a>
            </div>
            <div className="card link-div my-3 py-2">
                <h4>Security</h4>
                <a href="https://midsouthsecuritygroup.com/" target="_blank" rel="noopener noreferrer">Mid-South Security Group</a>
                <a href="https://www.boschsecurity.com/us/en/solutions/video-systems/solutions/" target="_blank" rel="noopener noreferrer">Bosch Security Cameras</a>
            </div>
            <div className="card link-div my-3 py-2">
                <h4>Network Communication</h4>
                <a href="https://www.ibm.com/support/knowledgecenter/SSLTBW_2.2.0/com.ibm.zos.v2r2.cbcpx01/onintr.htm" target="_blank" rel="noopener noreferrer">Network Communication Basics</a>
                <a href="https://www.fieldengineer.com/blogs/5-steps-to-a-successful-lan-implementation" target="_blank" rel="noopener noreferrer">Steps to Setting up a LAN</a>
            </div>
            <div className="card link-div my-3 py-2">
                <h4>Home Automation</h4>
                <a href="https://www.smarthome.com/pages/home-automation-guide-2020" target="_blank" rel="noopener noreferrer">Home Automation Guide</a>
                <a href="https://www.z-wave.com/" target="_blank" rel="noopener noreferrer">Z-Wave</a>
            </div>
            <div className="card link-div my-3 py-2">
                <h4>IoT</h4>
                <a href="https://www.zdnet.com/article/what-is-the-internet-of-things-everything-you-need-to-know-about-the-iot-right-now/" target="_blank" rel="noopener noreferrer">Article on Internet of Things (February 3, 2020)</a>
                <a href="https://internetofthingsagenda.techtarget.com/definition/Internet-of-Things-IoT" target="_blank" rel="noopener noreferrer">Further Reading on IoT</a>
            </div>
            <div className="card link-div my-3 py-2">
                <h4>Suppression</h4>
                <a href="https://www.keystonefire.com/blog/what-are-fire-suppression-systems-and-how-do-they-work/" target="_blank" rel="noopener noreferrer">Fire Suppression Basics Article (August 21, 2019)</a>
                <a href="https://www.firelite.com/en-US/Pages/Category.aspx?cat=HLS-FIRELITE&category=SuppressionCP" target="_blank" rel="noopener noreferrer">Fire-Lite Agent Releasing Panels</a>
            </div>
            <div className="card link-div my-3 py-2">
                <h4>Covid-19</h4>
                <a href="https://covid-19.elsevier.health/" target="_blank" rel="noopener noreferrer">Elselvier Covid-19 Research</a>
                <a href="https://digitaltouchsystems.com/covid-19-screening-kiosk/" target="_blank" rel="noopener noreferrer">Covid-19 Kiosk Solution</a>

            </div>
        </div >
    )
}
