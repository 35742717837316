import React, { useContext } from 'react';
import { Context } from '../Context';

export const Modal = () => {
    const { buttonModalObjects, crntInt } = useContext(Context);

    return (
        <div id="category-modal" className="modal fade" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mx-auto text-5">
                            {
                                buttonModalObjects[crntInt].heading
                            }
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-justify">
                        <img className="w-25 float-right ml-2" src={require("../assets/images/" + buttonModalObjects[crntInt].img2)} alt="category" />
                        {
                            buttonModalObjects[crntInt].content.split('\n').map((item, i) => {
                                return (<i key={i}>{item}<br /></i>);
                            })
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
