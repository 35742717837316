import React from "react";

export const Careers = () => {
    return (
        <div className="about-body text-center w-75 mx-auto">
            <h2 className="heading my-5 font-weight-bold">Careers</h2>
            <p className="bg-light p-5 card about-content">
                <i>
                    New employment opportunities are opening as we expand our operations. Feel free to visit this page if you are interesting in pursuing a career in a high-tech and growing industry with Alpha Low Voltage Systems.
                </i>
            </p>
            <h4 className="mt-5">Current Openings: 2</h4>
            <p className="bg-light p-5 mt-3 card about-content">
                <h5 className="text-center mb-4 font-weight-bold">Low voltage technician</h5>
                We are currently seeking a low voltage technician to join our growing company. Interested candidates should meet the following minimum qualifications.
                <ul className="mt-4 job-item-list">
                    <li className="pl-2">Possess a valid drivers license in the state of Missouri</li>
                    <li className="pl-2">Ability to handle physical workload</li>
                    <li className="pl-2">Ability to work from scissor and boom lifts</li>
                    <li className="pl-2">Exhibit punctuality and reliability</li>
                    <li className="pl-2">Must have reliable transportation</li>
                    <li className="pl-2">Must have basic hand tools</li>
                    <li className="pl-2">Possess good work ethic</li>
                    <li className="pl-2">Ability to work independently</li>
                    <li className="pl-2">At least 1 year of commercial experience in the low voltage field (preferred)</li>
                    <li className="pl-2">Strong problem-solving and critical thinking skills</li>
                </ul>
                <h6 className="text-center font-weight-bold mt-5">Responsibilites:</h6>
                <ul className="mt-3 job-item-list">
                    <li className="pl-2">Pull, terminate, and test CAT5 or CAT6 cable in commercial environments</li>
                    <li className="pl-2">Install support structures (trapeze style supports for conduit, backet trays, j-hooks, etc.)</li>
                    <li className="pl-2">Pull, terminate, splice, and test fiber optic cable in both OSP and ISP settings</li>
                    <li className="pl-2">Install, program, and maintain security systems and head end components (CCTV, switches, alarms, motion detectors, panels, etc.)</li>
                    <li className="pl-2">Install, program, and maintain access control devices and head end components (mag locks, card readers, door contacts, panels, etc.)</li>
                    <li className="pl-2">Install, program, and maintain fire alarm devices and head end components (smart boards, TVs, video walls, projectors, speakers, building automation, etc.)</li>
                </ul>
                <small>Pay is commensurate with experience. We are willing to train candidates with the right attitude and desired values. For inquiries, contact us by phone, email, or the 'Contact' page. If using the 'Contact' page, please put 'Employment' as the subject.</small>
            </p>
            <p className="bg-light p-5 mt-3 card about-content">
                <h5 className="text-center mb-4 font-weight-bold">Office assistant</h5>
                We are currently seeking an office assistant to join our growing company. Interested candidates should meet the following minimum qualifications.
                <ul className="mt-4 job-item-list">
                    <li className="pl-2">Must have reliable transportation to and from the office location</li>
                    <li className="pl-2">Exhibit punctuality and reliability</li>
                    <li className="pl-2">Must possess good interpersonal and communication skills</li>
                    <li className="pl-2">Must possess basic computer literacy</li>
                    <li className="pl-2">Ability to work independently</li>
                    <li className="pl-2">Sales experience (preferred)</li>
                </ul>
                <h6 className="text-center font-weight-bold mt-5">Responsibilites:</h6>
                <ul className="mt-3 job-item-list">
                    <li className="pl-2">Answer office calls</li>
                    <li className="pl-2">Set appointments</li>
                    <li className="pl-2">Coordinate field communications and operations</li>
                    <li className="pl-2">Provide friendly and professional customer service</li>
                    <li className="pl-2">Become familiar with industry standards to better assist customers</li>
                    <li className="pl-2">Facilitate sales by contacting prospective clients from a lead list via email and/or phone</li>
                </ul>
                <small>Pay is commensurate with experience. For inquiries, contact us by phone, email, or the 'Contact' page. If using the 'Contact' page, please put 'Employment' as the subject.</small>
            </p>
        </div>
    );
};
