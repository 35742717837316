import React, { useContext } from 'react';
import { Context } from '../Context';

export const Home = () => {
    const { updateCrntInt, buttonModalObjects, carouselImages } = useContext(Context);

    const handleClick = e => {
        const selection = e.currentTarget.getAttribute('data-attr');
        updateCrntInt(selection);
    }

    return (
        <div>
            <div className="home-showcase row align-items-center my-5 w-75 mx-auto bg-light">
                <div className="col-lg-7 carousel-container">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel">
                        <ol className="carousel-indicators">
                            {
                                carouselImages.map((item, index) => {
                                    let active;
                                    if (index === 0) {
                                        active = "active";
                                    } else {
                                        active = "";
                                    }
                                    return (
                                        <li key={index} data-target="#carouselExampleIndicators" data-slide-to={index} className={active}></li>
                                    );
                                })
                            }
                        </ol>
                        <div className="carousel-inner">
                            {
                                carouselImages.map((item, index) => {
                                    let active;
                                    if (index === 0) {
                                        active = "active";
                                    } else {
                                        active = "";
                                    }

                                    return (
                                        <div key={index} className={active + " carousel-item"}>
                                            <img src={require("../assets/images/" + item)} className="d-block w-100" alt={item} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <a className="carousel-links carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-arrows carousel-left" aria-hidden="true">&#8678;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-links carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-arrows carousel-right" aria-hidden="true">&#8680;</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-5 pr-4">
                    <h1 className="font-weight-light text-center font-weight-bold">Your one stop shop for all your low voltage needs!</h1>
                    <p className="mt-5"><i>We provide our customers with peace of mind, knowing that our highly competent professionals are working for you! Each project often presents a unique set of challenges that we take pride in rising to meet. Whether you're looking for a new installation, or service on an existing system, we provide the best solution for your needs. Give us a call today for a free estimate!</i></p>
                </div>
            </div>
            <div className="row mx-auto d-flex justify-content-center">
                {
                    buttonModalObjects.map((item, index) => {
                        return (
                            <button onClick={e => handleClick(e)} key={index} data-toggle="modal" data-attr={index} data-target="#category-modal" className="category-btn">
                                <h5 className="card-title text-light">{item.title}</h5>
                                <img src={require("../assets/images/" + item.img)} alt={item.title} />
                            </button>
                        );
                    })
                }
            </div>
        </div>
    )
}



