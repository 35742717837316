import React from 'react';

export const About = () => {
    return (
        <div className="about-body text-center w-75 mx-auto">
            <h2 className="heading my-5 font-weight-bold">About Us</h2>
            <p className="bg-light p-5 card about-content"><i>Our company is customer satisfaction-driven to find solutions to all low voltage needs. Whether it’s a custom/unique or a standard application, we strive for excellence. Our trained professionals have experience with a multitude of systems and designs for almost any project, at a competitive cost. We employ NICET 4 (National Institute for Certification in Engineering Technologies) technicians for enterprise level fire alarm, who are certified in all products we offer. We also deliver quotes on time to stay on schedule or emergency deadlines. Our team of world-class technologists are continually incorporating new and improved technologies into the systems and services we provide. Please visit our COVID-19 section, which showcases thermal sensors that interface with our access control systems. For inquiries about any of our products or services, please contact us today. We provide quotes at no cost.</i></p>
        </div>
    )
}
