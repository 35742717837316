import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
            <div id="nav-container" className="container">
                <div id="logo-div" className="text-center w-50">
                    <Link id="link" to="/">
                        <img id="logo" className="navbar-brand mx-auto" src={require("../assets/images/logo.png")} alt="logo" />
                    </Link>
                </div>
                <div id="navbar-toggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </div>
                <div className="collapse navbar-collapse box" id="navbarResponsive">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <NavLink exact to="/" className="nav-link">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/about" className="nav-link">About</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/content" className="nav-link">Info</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/contact" className="nav-link" >Contact</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/careers" className="nav-link" >Careers</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
