import React, { useState } from 'react';

export const Contact = () => {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        const btn = document.querySelector('#contact-submit');
        btn.setAttribute('disabled', true);

        const data = {
            email,
            subject,
            message
        };

        fetch('/contact', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log(res.status);
            const contactForm = document.getElementsByTagName('form')[0];
            const msg = document.createElement('h4');
            let text = 'Success!';
            let color = 'text-success';

            if (res.status !== 200) {
                text = 'Oops! An unexpected error occurred. Please try your request again later.';
                color = 'text-danger';
            }

            const content = document.createTextNode(text);
            msg.appendChild(content);
            msg.classList.add('mt-5');
            msg.classList.add('text-center');
            msg.classList.add(color);
            contactForm.appendChild(msg);
            setTimeout(() => {
                contactForm.removeChild(msg);
                btn.removeAttribute('disabled');
            }, 5000);

            setEmail('');
            setSubject('');
            setMessage('');
        }).catch(err => {
            console.log(err);
        });
    };

    const handleChange = (e) => {

        const value = e.target.value;

        switch (e.target.id) {
            case 'email':
                setEmail(value);
                break;
            case 'subject':
                setSubject(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    };

    return (
        <div className="w-50 mx-auto">
            <h2 className="text-center my-5 pt-5 font-weight-bold">Contact Us</h2>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="form-group">
                    <label htmlFor="email">Email address<span className="required">*</span></label>
                    <input onChange={e => handleChange(e)} type="email" className="form-control" id="email" aria-describedby="emailHelp" maxLength="200" required value={email} />
                    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject<span className="required">*</span></label>
                    <input onChange={e => handleChange(e)} type="text" className="form-control" id="subject" maxLength="50" value={subject} />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message<span className="required">*</span></label>
                    <textarea onChange={e => handleChange(e)} className="form-control" id="message" rows="7" maxLength="1000" required value={message} />
                </div>
                <button type="submit" id="contact-submit" className="btn btn-primary btn-block mb-5">Submit</button>
            </form>
        </div >
    )
}
