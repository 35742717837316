import React, { createContext, Component } from 'react';

export const Context = createContext();

class ContextProvider extends Component {
    state = {
        carouselImages: [
            'iot.png',
            'smarthome.png',
            'smarthome2.png',
            'cameras.png',
            'eye.png',
            'device.png',
            'network_hub.png',
            'fire_panel.png',
            'security_system.png'
        ],
        buttonModalObjects: [
            {
                title: 'FIRE ALARM',
                heading: 'Fire Alarm',
                content: 'We offer a variety of fire alarm products to satisfy life safety needs. Our goal is to meet or exceed expectations to customers. We offer design build, turnkey installations, or projects that have pre-determined specifications. For every fire alarm project, we complete a Record of Completion that is required by NFPA code, and warranty our work for one year thereafter. The ROC provides documentation of all parties involved in the project including: our company, electrical contractor (if needed), customer representative, and Authority Having Jurisdiction. We will meet or exceed NFPA codes and standards for all installations at a competitive rate, and compete against other quotes. For projects that we install and maintain, we will keep an accurate set of As-Build drawings for the site, both at the panel and on our database, with approvals from the AHJ. Our vendors include Bosch, Fire-Lite, Potter, and others. For inquiries regarding life safety, contact us for a free quote today!',
                id: 'fire-alarm',
                img: 'fire_alarm.png',
                img2: 'bosch_fire_alarm_thumb.png'
            },
            {
                title: 'ACCESS CONTROL',
                heading: 'Access Control',
                content: 'We offer the latest access control systems with IP and Weigand for secure access to controlled areas. These systems can be set up to be remotely or on-site controlled to add or remove users with a user-friendly interface. Card readers, code readers, finger print scanners, COVID-19 kiosks, and retinal scanners are some of the products we offer. Our technicians are certified professionals with customer satisfaction as their focus. Please call or email to get a quote today!',
                id: 'access-control',
                img: 'access_control.png',
                img2: 'access_control_thumb.png'
            },
            {
                title: 'INTRUSION ALARM',
                heading: 'Intrusion Alarm',
                content: 'We provide the latest technology to secure your facility with the ability to control your system remotely, and to integrate associated products including: motion detection cameras, motion sensors, glass break detectors, proximity switches, hold up switches, shock detectors, access control and many others. We can find a solution to your needs that meets expectations, and at a competitive rate. Our technicians are certified professionals with customer satisfaction as their focus. Please call or email to get a quote today!',
                id: 'intrusion',
                img: 'alarm.png',
                img2: 'motion_sensor_thumb.png'
            },
            {
                title: 'VIDEO SURVEILLANCE',
                heading: 'Video Surveillance',
                content: 'We use a variety of NVRs, DVRs, and IVRs for a comprehensive, high-resolution video surveillance to suit business or home use needs. We also have a variety of IP and analog cameras for the intended application and/or budget. We provide a turnkey installation, for which we install wiring, conduit, devices, head unit, equipment, and customer training, for a complete start to finish installation. We use products from vendors such as Bosch, Hikvision, Speco, Vicon, and others.',
                id: 'surveillance',
                img: 'camera.png',
                img2: 'camera_thumb.png'
            },
            {
                title: 'NETWORK COMMUNICATION',
                heading: 'Network Communication',
                content: 'We provide network cabling for PoE devices such as: IP cameras, voice over IP (VOIP), access points, phone, and other structured data cabling. Also ask about our off-site networking with apps that control camera systems for viewing and control, access control locking/unlocking doors, adding credentials, security system arming/disarming, home/business automation, and many other applications.',
                id: 'network',
                img: 'network.png',
                img2: 'netcom_thumb.png'
            },
            {
                title: 'HOME AUTOMATION',
                heading: 'Home Automation',
                content: 'We offer a range of home/business automation solutions to deliver the best in convenience, security, efficiency, and safety to our customers. At the heart of the system lies the controller, which governs the operation of any compatible device connected to the network. These devices include lighting, cameras, door locks, HVAC, washer/dryer, and many others. The devices are typically connected to the network via wireless signals, but can also be hard-wired and addressed. Wireless connections typically rely on either the Z-wave or Zigbee protocols. The appropriate standard is chosen based on the number of devices, dimensions of the building, budgetary considerations, compatibility with existing devices, and other factors. Our solutions can be integrated with Alexa, Google Assistance, Siri, and Cortana. Some of the controller brands we use are Insteon, Vera, HomeSeer, and others. Additionally, we offer custom solutions based on our proprietary software running on Raspberry Pi hardware, which provides a user interface for system access and control from any device. Contact us today for a free quote!',
                id: 'home-automation',
                img: 'home.png',
                img2: 'smart_home_thumb.png'
            },
            {
                title: 'WEB SOLUTIONS',
                heading: 'Web Solutions',
                content: 'With advancements in technology and connectivity, virtually every aspect of our daily lives involves devices we would not have historically classified as computers, yet toasters, home thermostats, lawn watering timers, lighting, automobiles, cellular phones, and many others are connected to the internet. When applied outside of the conventional computer or laptop environments, these control systems are known collectively as the Internet of Things (IoT). We offer custom solutions in the implementation of applications, control systems, and web development to suit your business or home needs.',
                id: 'web-solutions',
                img: 'web_dev_icon.png',
                img2: 'web_dev_thumb.png'
            },
            {
                title: 'SPECIAL HAZARDS / SUPPRESSION',
                heading: 'Special Hazards',
                content: 'We offer electronic fire suppression systems that can be either dedicated to a releasing system, or be part of the main fire alarm panel. These systems can be either addressable or conventional. Suppression systems can govern the dry releasing system or special hazard releasing system, both controlled by solenoid. Both of these systems normally have a three-way trigger or cross zone to actuate the solenoid. The triggers or cross zones normally consist of two or more electronic devices in a circuit, and the sprinkler head is triggered in order to disperse water, special hazard gas, or chemical. We produce these results via electronic fire suppression panels using conventional or addressable devices. Bosch, Potter, and Fire-Lite are among our trusted vendors for this equipment.',
                id: 'special-hazards',
                img: 'spl_haz.png',
                img2: 'suppression_thumb.png'
            }
        ],
        crntInt: 0
    }
    updateCrntInt = value => {
        this.setState({ crntInt: value })
    }
    render() {
        return (
            <Context.Provider value={{ ...this.state, updateCrntInt: this.updateCrntInt }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export default ContextProvider;

