import React from "react";
import { Navbar } from "./components/Navbar";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Content } from "./components/Content";
import { Contact } from "./components/Contact";
import { Careers } from "./components/Careers";
import { Footer } from "./components/Footer";
import { Modal } from "./components/Modal";
import { BrowserRouter, Route } from "react-router-dom";
import ContextProvider from "./Context";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <ContextProvider>
                    <Navbar />
                    <div className="body">
                        <div className="nav-spacing"></div>
                        <Route exact path="/" component={Home} />
                        <Route path="/about" component={About} />
                        <Route path="/content" component={Content} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/careers" component={Careers} />
                    </div>
                    <div className="footer-spacing"></div>
                    <Footer />
                    <Modal />
                </ContextProvider>
            </div>
        </BrowserRouter>
    );
}

export default App;
